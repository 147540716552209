<template>
	<div class="table">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 评论管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="container">
			<div class="handle-box">
				<el-input v-model="select_word" placeholder="筛选关键词(用户/标题/医生)" class="handle-input mr10" @keyup.enter.native="searchEnterFun"></el-input>
				<el-button type="primary" icon="search"  @click="search">搜索</el-button>
			</div>
			<el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" align="center" label="ID"></el-table-column>
				<el-table-column prop="nickname" align="center" label="用户昵称"></el-table-column>
				<el-table-column prop="avatarurl" align="center" label="头像">
					<template   slot-scope="scope">
					    <el-popover
					            placement="left"
					            title=""
					            width="500"
					            trigger="hover">
					        <img :src="scope.row.avatarurl" width="100" style="max-width: 100%" />
					        <img slot="reference" :src="scope.row.avatarurl" :alt="scope.row.avatarurl" style="max-width: 50px; height: auto; max-height: 100px">
					    </el-popover>
					    <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
					</template>
				</el-table-column>
				<el-table-column prop="title" align="center" label="视频标题"></el-table-column>
				<el-table-column prop="doctor_name" align="center" label="医生"></el-table-column>
				<el-table-column prop="content" align="center" label="内容"></el-table-column>
				<el-table-column prop="status" align="center" label="访问状态">
					<template slot-scope="scope">
					    <div v-if="scope.row.status==1">公开中</div>
					    <div v-else-if="scope.row.status==2">隐藏</div>
						<div v-else>审核中</div>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" align="center" label="评论时间"></el-table-column>
				<el-table-column label="操作" align="center">
				    <template slot-scope="scope">
						<template v-if="scope.row.status==0 || scope.row.status == 2">
							<el-button type="text" icon="el-icon-view" class="red" @click="handleUpdate(scope.$index, scope.row,1)">审核通过</el-button>
						</template>
						<template v-if="scope.row.status==0 || scope.row.status == 1">
							<el-button type="text" icon="el-icon-view" class="red" @click="handleUpdate(scope.$index, scope.row,2)">隐藏</el-button>
						</template>
				        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				    </template>
				</el-table-column>
			</el-table>
			<div class="pagination">
			    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			    </el-pagination>
			</div>
		</div>
		<!-- 删除提示框 -->
		<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
		    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="delVisible = false">取 消</el-button>
		        <el-button type="primary" @click="deleteRow">确 定</el-button>
		    </span>
		</el-dialog>
		
		<!-- 审核通过提示 -->
		<el-dialog title="提示" :visible.sync="showVisible" width="300px" center>
		    <div class="del-dialog-cnt">通过后，该评论将公开展示？</div>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="showVisible = false">取 消</el-button>
		        <el-button type="primary" @click="updateRow(1)">确 定</el-button>
		    </span>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="hideVisible" width="300px" center>
		    <div class="del-dialog-cnt">确定隐藏该评论？</div>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="hideVisible = false">取 消</el-button>
		        <el-button type="primary" @click="updateRow(2)">确 定</el-button>
		    </span>
		</el-dialog>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	export default {
		name: 'basetable',
		data(){
			return	{
				url: './vuetable.json',
				tableData:[],
				cur_page: 1,  //当前页
				number: 10,  //每页显示条数
				sumPage:10,  //总页数
				multipleSelection: [],
				select_word:'',
				del_list: [],
				loading:false, //加载中
				delVisible: false,
				is_search: false,
				v_id:'',	//从视频管理传过来的id
				showVisible: false,		//显示评论
				hideVisible:false,		//隐藏评论
			}
		},
		created(){
			//初始数据	
			// console.log('--------')
				// console.log(
			
			// this.showVTableData()
			// this.showVTableData()
			this.v_id =  this.$route.query.v_id
			this.getData();
		},
		computed:{
			data(){
				return this.tableData.filter((d) => {
				    let is_del = false;
				    for (let i = 0; i < this.del_list.length; i++) {
				        if (d.title === this.del_list[i].title) {
				            is_del = true;
				            break;
				        }
				    }
				    return d;
				})
			}
		},
		watch:{
			$route(){
				this.showVTableData()
			}
		},
		methods: {
			//从视频管理进入
			showVTableData(){
				this.v_id = this.$route.query.v_id
				this.getData()
			},
			
			// 分页导航
			handleCurrentChange(val) {
			    this.cur_page = val;
			    this.getData();
			},
			handleSelectionChange(val){
				  this.multipleSelection = val;
			},
		
			
			//获取表格数据
			getData(){
				var params = this.$qs.stringify({
					select_word:this.select_word,
					pageIndex: this.cur_page,
					number: this.number,
					v_id:this.v_id,		//从视频管理传过来的id
				})
				this.$api.post('ShopDoctorVideo/getCommentList',params,res=>{
					// console.log(res.data)
					this.tableData =  res.data.list
					this.sumPage = res.data.sumPage*10;
					this.cur_page = res.data.currentPage;
					// console.log(this.tableData)
					// console.log(res)
				},err=>{
					console.log(err)
					this.tableData = [];
					this.sumPage = 1*10;
					this.cur_page = 1;
					this.$message.error(err.msg);
				})
			},
			//按下回车键发起搜索
			searchEnterFun:function(e){
			    var keyCode = window.event? e.keyCode:e.which;
			    if(keyCode == 13){
			        this.search();
			    }
			},
			search() {
			    this.is_search = true;
			    this.getData();
			},
			handleDelete(index, row) {
			    this.idx = index;
			    this.form = row;
			    this.delVisible = true;
			},
			// 确定删除
			deleteRow(){
				var params=this.$qs.stringify({
					id: this.form.id
				});
				this.$api.post('ShopDoctorVideo/delComment', params, res => {
					this.getData();
					this.$message.success(res.msg+res.data+"条数据");
				}, err => {
					this.$message.error(err.msg);
				});
				this.delVisible = false;
			},
			
			//审核状态
			handleUpdate(index,row,type){
				if(type == 1){		//显示
					this.showVisible = true
				}else if(type == 2){	//隐藏
					this.hideVisible = true
				}
				this.form =  row
			},
			updateRow(type){
				let status = type==1?1:2
				var params=this.$qs.stringify({
					id: this.form.id,
					status:type
				});
				this.$api.post('ShopDoctorVideo/updateCommentStatus', params, res => {
					this.getData();
					this.$message.success(res.msg+res.data+"条数据");
				}, err => {
					this.$message.error(err.msg);
				});
				type == 1 ?this.showVisible = false:this.hideVisible = false
			}
			
		
		},
	}
</script>

<style>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	}
	.avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	}
	.avatar {
	    width: 100%;
	    /*height: 100%;*/
	    display: block;
	}
</style>
